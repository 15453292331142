import { Controller } from 'stimulus';

// Responsible for looking after the state of the sharing urls form.
// We swap between two different forms based on the state of a radio button, and
// also show adjudication fields on the custom form based on the state of a checkbox.
export default class extends Controller {
  static targets = [
    'custom',
    'autogenerated',
    'customForm',
    'autogeneratedForm',
    'matchingUrls',
    'adjudicationFields'
  ];

  initialize() {
    if (this.customTarget.checked) {
      this.showCustomForm();
    } else {
      this.showAutogeneratedForm();
    }

    this.open = true;

    if (this.matchingUrlsTarget.checked) {
      this.toggleAdjudicationFields();
    }
  }

  showCustomForm() {
    this.customFormTarget.style.display = 'block';
    this.autogeneratedFormTarget.style.display = 'none';
  }

  showAutogeneratedForm() {
    this.customFormTarget.style.display = 'none';
    this.autogeneratedFormTarget.style.display = 'block';
  }

  toggleAdjudicationFields() {
    this.open = !this.open;
    if (!this.open) {
      this.adjudicationFieldsTarget.style.display = 'none';
    } else {
      this.adjudicationFieldsTarget.style.display = 'flex';
    }
  }
}
