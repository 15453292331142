import { Controller } from 'stimulus'
import axios from 'axios'

// Set header so Rails know we're making XHR requests.
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

// Public: Look after a question on the dashboard. Monitors for state changes
// and re-renders the question.
export default class extends Controller {
  static targets = [ 'content' ]

  initialize() {
    this.cable = this.application.cable;
    this.id = this.element.getAttribute('data-id');
  }

  connect() {
    // Subscribe to state changes for this question
    this.cable.subscriptions.create({
      channel: 'QuestionStateChannel',
      id: this.id
    }, {
      received: () => {
        // When we receive a state change message, we don't really care 
        // (for now) what was in the message. Just re-fetch the HTML.
        axios.get(this.element.getAttribute('data-loader-url')).
          then(({data}) => { this.element.innerHTML = data });
      }
    });
  }
}
