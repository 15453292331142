import { Controller } from 'stimulus';

// Handle hiding/showing the guidance when submitting an answer to a question.
export default class extends Controller {
  static targets = [ 'content', 'button' ];

  initialize() {
    try {
      this.open = JSON.parse(localStorage.getItem('openGuidance'));
      if (this.open === null) {
        this.open = false
      }
    } catch(e) {
      this.open = false;
    }
  }

  connect() {
    if (this.open) {
      this.show();
    } else {
      this.hide();
    }
  }

  toggle() {
    this.open = !this.open;
    try {
      // localStorage can't store booleans. I'm guessing this is browser 
      // agnostic. TODO: Use a higher-level library that abstracts away
      // from localStorage.
      localStorage.setItem('openGuidance', JSON.stringify(this.open));
    } catch(e) {}

    if (!this.open) {
      this.hide();
    } else {
      this.show();
    }
  }

  show() {
    this.contentTarget.style.display = 'block';
    this.buttonTarget.innerHTML = I18n.t('ideas.question_summary.hide_guidance');
  }

  hide() {
    this.contentTarget.style.display = 'none';
    this.buttonTarget.innerHTML = I18n.t('ideas.question_summary.show_guidance');
  }
}
