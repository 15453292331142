import { Controller } from 'stimulus';

// Generic modal controller. See writing tips for an example
export default class extends Controller {
  static targets = [ 'modal' ];

  connect() {
    if (this.data.has('start-visible')) {
      this.show();
    } else {
      this.hide();
    }
  }

  show(e) {
    this.modalTarget.classList.add('is-active');

    e && e.preventDefault();
  }

  hide(e) {
    this.modalTarget.classList.remove('is-active');
    e && e.preventDefault();
  }
}
