import { Controller } from "stimulus"
import moment from 'moment'

// Timer controller
export default class extends Controller {
  static targets = [ 'timer', 'modal' ]

  initialize() {
    // Pull out initial state, parse.
    const countdownTo = this.element.getAttribute('data-countdown-to');
    this.questionId = this.element.getAttribute('data-question-id');
    this.countdownTo = moment(countdownTo);
  }

  connect() {
    this.startTimer();
  }

  disconnect() {
    this.stopTimer();
  }

  startTimer() {
    this.timerInterval = setInterval(this.tick.bind(this), 250);
  }

  stopTimer() {
    if (this.timerInterval) { clearInterval(this.timerInterval); }
  }

  tick() {
    // Convert time remaining into seconds
    const duration = moment.duration(this.countdownTo.diff(moment()));
    const durationSeconds = Math.round(duration.asSeconds());

    if (durationSeconds < 0) {
      // If timer has ran out (below zero so that the timer visually sits at
      // zero for a second), show modal.
      clearInterval(this.timerInterval);
      this.modalTarget.classList.add('is-active');
      window.Intercom('trackEvent', 'Ran out of time adjudicating', {question_id: this.questionId});
    } else {
      // Update label
      this.timerTarget.textContent = `${Math.round(duration.asSeconds())}s`;
    }
  }

}
