/* eslint no-console:0 */

// Support for IE11. See <https://github.com/stimulusjs/stimulus/tree/master/packages/%40stimulus/polyfills>
import '@stimulus/polyfills'

// Load in Stimulus controllers
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import ActionCable from 'actioncable'

const application = Application.start();

// Create an ActionCable instance our controllers can access. If we need to
// access this from some React components one day, we can spin it out to 
// a singleton elsewhere.
application.cable = ActionCable.createConsumer();

const context = require.context("controllers", true, /.js$/);
application.load(definitionsFromContext(context));
