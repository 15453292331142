import { Controller } from 'stimulus';

// Responsible for looking after the state of the user qualifications form.
export default class extends Controller {
  static targets = [
    'languages',
    'otherFieldsTemplate',
    'languagesDropdown',
    'otherFields'
  ];

  initialize() {
    var id = this.data.get('id');
    if (this.languagesTarget.value == id) {
      this.open = true;
      this.showOtherFields();
    }
  }

  showOtherFields() {
    this.open = true;

    var content = this.otherFieldsTemplateTarget.content;
    var div = document.createElement('div');
    div.appendChild( content.cloneNode(true) );
    var html = div.innerHTML;

    this.languagesDropdownTarget.insertAdjacentHTML('afterend', html);
  }

  hideOtherFields() {
    if (this.open) {
      this.open = false;
      this.otherFieldsTarget.remove();
    }
  }

  toggleOtherFields() {
    var id = this.data.get('id');

    if (this.languagesTarget.value == id) {
      this.showOtherFields();
    } else {
      this.hideOtherFields();
    }
  }
}
