import { Controller } from 'stimulus'

const primaryClass = 'is-primary';
const hiddenClass = 'is-hidden';

// Responsible for setting the correct IDs of the organisation members to be
// added to a group.
export default class extends Controller {
  static targets = [ 
    'checkbox',
    'deselectLink',
    'actionButton', 
    'createGroupButton', 
    'addToGroupButton',
    'archiveMembersButton',
    'confirmArchiveModal'
  ];

  // Getter for new group base path.
  get createGroupBasePath() {
    return this.data.get('createGroupBasePath') + '?';
  }

  // Getter for membership archive base path.
  get archiveMembersBasePath() {
    return this.data.get('archiveMembersBasePath') + '?';
  }

  memberChecked() {
    this.updateButtons();
  }

  confirmArchive(event) {
    event.preventDefault();
    this.confirmArchiveModalTarget.classList.add('is-active');
  }

  closeModal() {
    this.confirmArchiveModalTarget.classList.remove('is-active');
  }

  // Select everybody in the org.
  selectAll(event) {
    event.preventDefault();
    this.checkboxTargets.forEach((c) => c.checked = true);

    // Update the buttons to not pass in any ids.
    this.createGroupButtonTarget.href = this.createGroupBasePath;
    this.archiveMembersButtonTarget.href = this.archiveMembersBasePath;

    this.enableActionButton();
  }

  // Select all visible checkboxes.
  selectVisible(event) {
    event.preventDefault();
    this.checkboxTargets.forEach((c) => c.checked = true);
    this.updateButtons();

    this.enableActionButton();
  }

  // Uncheck all checkboxes.
  deselectAll() {
    this.checkboxTargets.forEach((c) => c.checked = false);
    this.disableActionButton();
  }

  // Update the new group button with the ids of the selected org members.
  updateButtons() {
    // Find the ids of the selected org members.
    let checkedIds = this.checkboxTargets.reduce((acc, checkbox) => {
      if (checkbox.checked) {
        acc.push(checkbox.value);
      }
      return acc;
    }, []);

    if (checkedIds.length == 0) {
      this.disableActionButton();
    } else {
      this.createGroupButtonTarget.href = this.queryString(checkedIds, this.createGroupBasePath);
      this.archiveMembersButtonTarget.href = this.queryString(checkedIds, this.archiveMembersBasePath);
      this.addToGroupButtonTargets.forEach((e) => {
        // Reset the baseHref
        let baseHref = e.href.split("?")[0] + '?';
        e.href = this.queryString(checkedIds, baseHref);
      })
      this.enableActionButton();
    }
  }

  // Disable the action button, and hide the link to deselect.
  disableActionButton() {
    this.actionButtonTarget.classList.remove(primaryClass);
    this.actionButtonTarget.disabled = true;
    // If the action button is disabled, it means there's nothing to deselect either.
    this.deselectLinkTarget.classList.add(hiddenClass);
  }

  // Un-disable the action button and show the link to deselect everyone.
  enableActionButton() {
    this.actionButtonTarget.classList.add(primaryClass);
    this.actionButtonTarget.disabled = false;
    this.deselectLinkTarget.classList.remove(hiddenClass);
  }

  // Returns the base path with the query string including all checked org member ids
  queryString(checkedIds, path) {
    const basePath = path + '&';
    const queryString = checkedIds.map((id) => `member_ids[]=${id}`).join('&');
    return basePath + queryString;
  }
}
